import gsap from 'gsap';
import Viewport from './core/Viewport';
import Components from './core/Components';
import lazySizes from './lib/lazysizes';
import Config from './core/Config';

gsap.defaults({ overwrite: 'auto' });

const init = () => {
    Config.init(window.vrsgConfig);
    Viewport.init();
    Components.init();
    Viewport.initTabbing();
    lazySizes.init();
};

require('doc-ready')(init);
