import $ from '../core/Dom';
import ShopifyUtil from '../lib/ShopifyUtil';
import Dispatch from '../core/Dispatch';
import { CART_UPDATE } from '../lib/events';

export default (el, props) => {
    const form = $(el);
    const variantIdField = form.find('select');

    const onShopifyUpdate = cart => {
        console.log('onShopifyUpdate', cart);
        Dispatch.emit(CART_UPDATE, cart);
    };

    const onSubmit = e => {
        console.log('onSubmit');
        e.preventDefault();
        const selectedVariantId = variantIdField.val();
        const client = ShopifyUtil.getClient();
        ShopifyUtil.getCheckout(client, checkout => {
            client.checkout.addLineItems(checkout.id, [
                {
                    variantId: `gid://shopify/ProductVariant/${selectedVariantId}`,
                    quantity: 1
                }
            ]).then(onShopifyUpdate);
        });
    };

    const init = () => {
        console.log(props);
        form.on('submit', onSubmit);
    };

    const destroy = () => {
        form.off('submit', onSubmit);
    };

    return {
        init,
        destroy
    };
};
