import $ from '../core/Dom';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    let flkty;

    const init = () => {
        loadFlickity(Flickity => {
            flkty = new Flickity(el, {
                contain: true,
                dragThreshold: 10,
                cellAlign: 'left',
                prevNextButtons: false,
                pageDots: false,
                wrapAround: false,
                freeScroll: true,
                groupCells: false,
                resize: true,
                draggable: true,
                freeScrollFriction: 0.045,
                arrowShape: 'M25,50l22,22l3.3-3.3L33.9,52.3H75v-4.7H33.9l16.4-16.4L47,28L25,50z'
            });

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
            });
        });
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    };

    return {
        init,
        destroy
    };
};
