import Config from '../core/Config';

let shopifyClient = null;
let checkoutId = sessionStorage.getItem('checkoutId');

const getClient = () => {
    console.log('getClient');
    if (!shopifyClient) {
        shopifyClient = ShopifyBuy.buildClient({
            domain: Config.get('shopifyHost'),
            storefrontAccessToken: Config.get('shopifyToken')
        });
    }
    return shopifyClient;
};

const getCheckout = (client, callback) => {
    console.log('getCheckout');
    if (checkoutId) {
        client.checkout.fetch(checkoutId).then(checkout => {
            callback(checkout);
        });
    } else {
        client.checkout.create().then(checkout => {
            checkoutId = checkout.id;
            sessionStorage.setItem('checkoutId', checkoutId);
            callback(checkout);
        });
    }
};

export default {
    getClient,
    getCheckout
};
