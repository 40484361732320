import gsap from 'gsap';
import $ from '../core/Dom';

export default el => {
    const dom = $(el);
    const filters = dom.find('button[data-role]');
    const targets = dom.find('li[data-role]');

    const onFilter = e => {
        const role = $(e.target).data('role');
        if (role) {
            targets.each(node => {
                const target = $(node);
                target.attr('hidden', target.data('role') !== role ? '' : null);
            });
        } else {
            targets.attr('hidden', null);
        }
        filters.removeClass('active');
        dom.find(`button[data-role="${role}"]`).addClass('active');
    };

    const init = () => {
        filters.on('click', onFilter);
    };

    const destroy = () => {
        filters.off('click', onFilter);
    };

    return {
        init,
        destroy
    };
};
