import $ from '../core/Dom';
import Dispatch from '../core/Dispatch';
import { CART_UPDATE } from '../lib/events';
import ShopifyUtil from '../lib/ShopifyUtil';

export default el => {
    const dom = $(el);
    const cartCounter = dom.find('[data-counter]');
    const wrapper = dom.find('[data-wrapper]');

    const update = cart => {
        cartCounter.text(cart.lineItems.length);
        dom.attr('href', cart.webUrl).toggleClass('hidden', !cart.lineItems.length);
        if (cart.lineItems.length) {
            wrapper.addClass('bounce');
        }
    };

    const onUpdate = (key, cart) => {
        update(cart);
    };

    const init = () => {
        Dispatch.on(CART_UPDATE, onUpdate);
        ShopifyUtil.getCheckout(ShopifyUtil.getClient(), update);
        wrapper.on('animationend', () => {
            wrapper.removeClass('bounce');
        });
    };

    const destroy = () => {
        Dispatch.off(CART_UPDATE, onUpdate);
    };

    return {
        init,
        destroy
    };
};
