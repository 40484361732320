import $ from '../core/Dom';
import ShopifyUtil from '../lib/ShopifyUtil';
import Dispatch from '../core/Dispatch';
import { CART_UPDATE } from '../lib/events';

export default (el, props) => {
    const form = $(el);
    const inputs = form.find('input');
    const submit = form.find('[data-submit]');
    const numberOfOptions = form.find('[data-options]').length;
    const variantIdField = form.find('[data-variant]');

    // Create a helper function to test a map of options against known variants:
    const findVariant = options => {
        if (Object.keys(options).length !== numberOfOptions) {
            return null;
        }
        const { variants } = props;
        // Use labels for the inner and outer loop so we can break out early:
        variant: for (const v in variants) {
            option: for (const o in options) {
                // Option values are stored as `option1`, `option2`, or `option3` on each variant:
                if (variants[v][`option${o}`] !== options[o]) {
                    // Didn't match one of the options? Bail:
                    continue variant;
                }
            }

            // Nice, all options matched this variant! Return it:
            return variants[v];
        }
        return null;
    };

    const onUpdate = e => {
        console.log('onUpdate');
        const selectedOptions = {};
        inputs.each(node => {
            if (node.checked) {
                const input = $(node);
                selectedOptions[input.data('position')] = input.val();
            }
        });
        const variant = findVariant(selectedOptions);
        if (variant) {
            variantIdField.get(0).value = variant.id;
            submit.attr('disabled', null);
        } else {
            variantIdField.get(0).value = '';
            submit.attr('disabled', '');
        }
        console.log(selectedOptions, variant);
    };

    const onShopifyUpdate = cart => {
        console.log('onShopifyUpdate', cart);
        Dispatch.emit(CART_UPDATE, cart);
    };

    const onSubmit = e => {
        console.log('onSubmit');
        e.preventDefault();
        const selectedVariantId = variantIdField.val();
        const client = ShopifyUtil.getClient();
        ShopifyUtil.getCheckout(client, checkout => {
            client.checkout.addLineItems(checkout.id, [
                {
                    variantId: `gid://shopify/ProductVariant/${selectedVariantId}`,
                    quantity: 1
                }
            ]).then(onShopifyUpdate);
        });
    };

    const init = () => {
        console.log(props);
        form.on('submit', onSubmit);
        inputs.on('change', onUpdate);
    };

    const destroy = () => {
        form.off('submit', onSubmit);
        inputs.off('change', onUpdate);
    };

    return {
        init,
        destroy
    };
};
