import $ from '../core/Dom';

const loadFlickity = require('bundle-loader?lazy&name=[name]!flickity');

export default el => {
    let flkty;
    const dom = $(el);
    const wrapper = dom.find('[data-slides]').get(0);
    const slides = dom.find('[data-slide]');

    const fixAriaHidden = () => {
        if (flkty) {
            flkty.cells.forEach(({ element }) => {
                element.removeAttribute('aria-hidden');
            });
        }
    };

    const checkStatus = () => {
        if (flkty) {
            fixAriaHidden();
        }
    };

    const init = () => {
        loadFlickity(Flickity => {
            flkty = new Flickity(wrapper, {
                contain: true,
                dragThreshold: 10,
                cellAlign: 'left',
                cellSelector: '[data-slide]',
                prevNextButtons: true,
                pageDots: false,
                wrapAround: false,
                freeScroll: true,
                groupCells: true,
                freeScrollFriction: 0.045,
                arrowShape: 'M25,50l22,22l3.3-3.3L33.9,52.3H75v-4.7H33.9l16.4-16.4L47,28L25,50z'
            });

            flkty.on('dragStart', () => {
                document.ontouchmove = e => e.preventDefault();
            });

            flkty.on('dragEnd', () => {
                document.ontouchmove = () => true;
            });

            flkty.on('select', checkStatus);
            flkty.on('ready', checkStatus);

            slides.on('focusin', e => {
                wrapper.parentNode.scrollLeft = 0;
                const { triggerTarget: link } = e;
                const cell = flkty.cells.find(({ element }) => element.contains(link));
                if (!cell) {
                    return;
                }
                const index = flkty.cells.indexOf(cell);
                if (index > -1) {
                    flkty.selectCell(index);
                }
            });

            checkStatus();
        });
    };

    const destroy = () => {
        if (flkty) {
            flkty.destroy();
            flkty = null;
        }
    };

    return {
        init,
        destroy
    };
};
